// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dancer-js": () => import("./../src/pages/dancer.js" /* webpackChunkName: "component---src-pages-dancer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-production-js": () => import("./../src/pages/production.js" /* webpackChunkName: "component---src-pages-production-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-workshop-js": () => import("./../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */),
  "component---src-templates-dancer-js": () => import("./../src/templates/dancer.js" /* webpackChunkName: "component---src-templates-dancer-js" */),
  "component---src-templates-production-js": () => import("./../src/templates/production.js" /* webpackChunkName: "component---src-templates-production-js" */),
  "component---src-templates-workshop-js": () => import("./../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */)
}

